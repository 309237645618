<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between align-center mb-2">
      <router-link
        v-if="this.selectedBrandId"
        :to="
          `/session/${this.sessionId}/pos/${this.worksessionPosId}/${this.selectedBrandId}`
        "
      >
        <b-button class="mr-1" variant="outline-secondary" size="sm">
          <b-icon icon="arrow-left"></b-icon> volver
        </b-button>
      </router-link>
    </div>
    <div class="border d-flex justify-content-center align-center mt-2 mb-2">
      <template v-if="posSession">
        <span class="trencadis-title my-3"
          >[{{ posSession.po.code || posSession.po.id }}]
          {{ posSession.po.name }} -
          {{ selectedBrand ? selectedBrand.name : "" }}</span
        >
        <sessionTagLabels
          :postaglabels="posSession.po.postaglabels"
        ></sessionTagLabels>
      </template>
    </div>

    <div class="d-flex flex-column mt-2 mb-4" v-if="posSession">
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="d-flex justify-content-start align-center flex-wrap"
        >
          <template v-for="(brandItem, bKey) in worksessionPosBrands">
            <b-button
              variant="outline-secondary"
              class="mr-2 mt-2"
              v-if="selectedBrandId !== brandItem.id"
              :key="`brandnavbtn_${bKey}`"
              @click="onClickSelectedBrand(brandItem.id)"
            >
              <v-badge color="pink" inline dot>
                {{ brandItem.name }}
              </v-badge>
            </b-button>
            <b-button
              :key="`brandnavbtn_${bKey}`"
              variant="outline-secondary"
              class="mr-2 mt-2"
              @click="onClickSelectedBrand(brandItem.id)"
              v-else
            >
              <v-badge color="green" inline dot>
                {{ brandItem.name }}
              </v-badge>
            </b-button>
          </template>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-if="selected_image && selected_promatItem"
      v-model="showImageDialog"
      scrollable
      width="auto"
    >
      <v-card>
        <v-card-title class="d-flex justify-content-between">
          <span class="text-h5">{{ selected_promatItem?.name }}</span>
          <span class="text-h6">{{
            selected_promatItem?.grams
              ? `${selected_promatItem?.grams} ${$t("Grams")}`
              : ""
          }}</span>
        </v-card-title>
        <v-card-text style="padding-bottom: 0 !important;">
          <v-container class="text--primary">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img style="max-width: 100%;" :src="selected_image" alt="" />
              </v-col>
            </v-row>
            <v-row v-if="selected_promatItem?.description">
              <v-col cols="12" sm="12" md="12">
                <p class="text-h5 sectionTitle">
                  {{ $t("brands.description") }}
                </p>
                <p class="sectionContents">
                  {{ selected_promatItem?.description }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="selected_promatItem?.salesArguments">
              <v-col cols="12" sm="12" md="12">
                <p class="text-h5 sectionTitle">{{ $t("Sales Arguments") }}</p>
                <p class="sectionContents">
                  {{ selected_promatItem?.salesArguments }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="selected_promatItem?.executionInstruction">
              <v-col cols="12" sm="12" md="12">
                <p class="text-h5 sectionTitle">
                  {{ $t("Execution Instruction") }}
                </p>
                <p class="sectionContents">
                  {{ selected_promatItem?.executionInstruction }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedAddActionItem"
      v-model="showAddActionDialog"
      scrollable
      width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ titleOfAddActionDialog }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-autocomplete
                  v-model="selectedAddActionItem.surveyActionId"
                  v-bind:label="`${$t('brands.Actions')}`"
                  :items="surveyActionsForBrand"
                  item-text="label"
                  item-value="id"
                  filled
                  :hide-details="true"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-textarea
                  v-model="selectedAddActionItem.comments"
                  v-bind:label="$t('comments')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAddActionsDialog">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onClickSaveAddActions(selectedAddActionItem)"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="surveyList && surveyList.length > 0">
      <div class="d-flex flex-column mt-2 mb-4">
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="4"
            class="d-flex justify-content-start align-center flex-wrap"
            v-if="filterableSurveyList.length > 1"
          >
            <v-autocomplete
              v-model="filterCriteria['surveyId']"
              v-bind:label="$t('survey')"
              item-text="name"
              item-value="id"
              :items="filterableSurveyList"
              filled
              outlined
              hide-no-data
              hide-details
              class="mr-4"
              clearable
              @change="onChangeSurvey"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="4"
            class="d-flex justify-content-start align-center flex-wrap"
          >
            <v-autocomplete
              v-model="filterCriteria['surveyComponentId']"
              v-bind:label="$t('component')"
              item-text="name"
              item-value="id"
              :items="filterableSurveyComponentList"
              filled
              outlined
              hide-no-data
              hide-details
              class="mr-4"
              clearable
              @change="onChangeSurveyComponent"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="4"
            class="d-flex justify-content-start align-center flex-wrap"
          >
            <v-autocomplete
              v-if="
                assignTypeForSelectedComponent === 'PRODUCT' ||
                  assignTypeForSelectedComponent === 'MATERIAL'
              "
              v-model="filterCriteria['surveyProOrMatId']"
              v-bind:label="
                assignTypeForSelectedComponent === 'PRODUCT'
                  ? $t('Product')
                  : $t('Material')
              "
              item-text="name"
              item-value="id"
              :items="filterableProOrMatList"
              filled
              outlined
              hide-no-data
              hide-details
              class="mr-4"
              clearable
              @change="onChangeSurveyComponentProOrMat"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex flex-column mt-2 mb-4">
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="5"
            class="d-flex justify-content-start align-center flex-wrap"
          >
            Punt:
            <span class="mx-1 bold">
              {{ worksessionSurveyResponse?.surveyBrandScore }}
            </span>
            ({{ posBrandPSObjective }} Obj -
            {{ displayPosBrandPSLastVisit }} Ant)
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="7"
            class="d-flex justify-content-start align-center flex-wrap"
          >
            <v-checkbox
              class="mt-0 mx-2"
              :label="`es obligatoria?`"
              hide-details
              v-model="filterCriteria['isMandatory']"
              @change="onChangeFilterSurveys"
            ></v-checkbox>
            <v-checkbox
              class="mt-0 mx-2"
              :label="`0 pts`"
              hide-details
              v-model="filterCriteria['zero_points']"
              @change="onChangeFilterSurveys"
            ></v-checkbox>
            <v-checkbox
              class="mt-0 mx-2"
              :label="`Acciones`"
              hide-details
              v-model="filterCriteria['actions']"
              @change="onChangeFilterSurveys"
            ></v-checkbox>
            <v-checkbox
              class="mt-0 mx-2"
              :label="`Sin Contestar`"
              hide-details
              v-model="filterCriteria['no_answers']"
              @change="onChangeFilterSurveys"
            ></v-checkbox>
            <v-checkbox
              class="mt-0 mx-2"
              :label="`Contestadas`"
              hide-details
              v-model="filterCriteria['answers']"
              @change="onChangeFilterSurveys"
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>

      <template v-for="(surveyListItem, surveyListKey) in filteredSurveyList">
        <div
          v-if="!surveyListItem.survey_components.length"
          :key="`survey_list_${surveyListKey}`"
          class="mt-3"
        >
          <b-alert variant="warning" show=""
            >{{ surveyListItem.name }} - No hay ninguna encuesta</b-alert
          >
        </div>
        <template v-else>
          <v-card
            elevation="5"
            class="mb-3"
            outlined
            :key="`survey_list_${surveyListKey}`"
            v-show="
              !filterCriteria['surveyId']
                ? true
                : filterCriteria['surveyId'] === surveyListItem.id
                ? true
                : false
            "
          >
            <v-card-title>{{ surveyListItem.name }}</v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <div
                class="mb-3"
                :key="`survey_list_item_component_${surveyListKey}_${comKey}`"
                v-for="(component, comKey) in surveyListItem?.survey_components"
              >
                <div
                  v-show="
                    !filterCriteria['surveyComponentId']
                      ? true
                      : filterCriteria['surveyComponentId'] === component.id
                      ? true
                      : false
                  "
                >
                  <h4
                    v-if="component.name && !component.hidelabel"
                    class="title_component"
                  >
                    {{ component.name }}
                  </h4>
                  <v-row
                    v-for="(proOrMat, proOrMatKey) in getProOrMatList(
                      surveyListItem,
                      component
                    )"
                    :key="`component_promat_${proOrMatKey}`"
                  >
                    <v-col
                      cols="12"
                      v-show="
                        !filterCriteria['surveyProOrMatId']
                          ? true
                          : filterCriteria['surveyProOrMatId'] === proOrMat?.id
                          ? true
                          : false
                      "
                    >
                      <v-row
                        v-show="
                          getVisibilityProOrMat(
                            surveyListItem,
                            component,
                            proOrMat
                          )
                        "
                      >
                        <v-col cols="12" xs="12" sm="6">
                          <h5 class="text-right" v-if="proOrMat">
                            {{ proOrMat.name }}
                          </h5>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                          <img
                            v-if="
                              getProOrMatImageComponent(
                                component.assignType,
                                proOrMat,
                                true
                              )
                            "
                            style="border: 1px solid #aaa; max-width: 100%; cursor: pointer;"
                            :src="
                              getProOrMatImageComponent(
                                component.assignType,
                                proOrMat,
                                true
                              )
                            "
                            @click="
                              onItemShowImageDialog(
                                component.assignType,
                                proOrMat,
                                false
                              )
                            "
                            alt=""
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <template
                          v-for="(question,
                          k_question) in component.survey_questions"
                        >
                          <v-col
                            cols="12"
                            xs="12"
                            :sm="
                              ![
                                QUESTION_TYPES.PHOTO,
                                QUESTION_TYPES.LONGTEXT,
                              ].includes(getQuestionType(question))
                                ? 6
                                : 12
                            "
                            :md="
                              ![
                                QUESTION_TYPES.PHOTO,
                                QUESTION_TYPES.LONGTEXT,
                              ].includes(getQuestionType(question))
                                ? 3
                                : 12
                            "
                            :key="
                              `component_proOrMat_${surveyListKey}_${comKey}_question_${k_question}`
                            "
                          >
                            <SurveyQuestion
                              v-show="
                                getVisibilityQuestion(
                                  surveyListItem,
                                  component,
                                  question,
                                  proOrMat
                                )
                              "
                              :detectedSurveyChainIdForPuncDetails="
                                detectedSurveyChainIdForPuncDetails
                              "
                              :optionScores="
                                getOptionScores(
                                  question,
                                  component.assignType,
                                  proOrMat ? proOrMat.id : null
                                )
                              "
                              :initialValue="
                                getInitialQuestionValue(
                                  surveyListItem.id,
                                  question.id,
                                  component.assignType,
                                  proOrMat ? proOrMat.id : null,
                                  2
                                )
                              "
                              :currentScore="
                                getInitialQuestionValue(
                                  surveyListItem.id,
                                  question.id,
                                  component.assignType,
                                  proOrMat ? proOrMat.id : null,
                                  1
                                )
                              "
                              :maximumScore="
                                getInitialQuestionValue(
                                  surveyListItem.id,
                                  question.id,
                                  component.assignType,
                                  proOrMat ? proOrMat.id : null,
                                  3
                                )
                              "
                              @input="
                                (result, typeStr) =>
                                  persistQuestionValue(
                                    result,
                                    typeStr,
                                    surveyListItem.id,
                                    question.id,
                                    component.assignType,
                                    proOrMat ? proOrMat.id : null
                                  )
                              "
                              :question="question"
                            ></SurveyQuestion>
                          </v-col>
                        </template>
                      </v-row>
                      <v-row v-if="component.isAction">
                        <v-col
                          cols="12"
                          xs="12"
                          sm="3"
                          class="text-left d-flex align-center"
                        >
                          <b-button
                            class="mr-1"
                            variant="success"
                            size="sm"
                            style="color: #fff !important;"
                            @click="
                              onClickAddActions(
                                surveyListItem,
                                component,
                                proOrMat
                              )
                            "
                            v-show="
                              getVisibilityActionProOrMat(
                                surveyListItem,
                                component,
                                proOrMat
                              )
                            "
                          >
                            <b-icon icon="plus"></b-icon>
                            {{ $t("Add Action") }}
                          </b-button>
                        </v-col>
                        <v-col cols="12" xs="12" sm="9" class="text-left">
                          <v-chip
                            class="m-2 added_action_chip"
                            v-for="(actionItem, actionKey) in getAddedActions(
                              surveyListItem,
                              component,
                              proOrMat
                            )"
                            v-show="
                              getVisibilityAction(
                                surveyListItem,
                                component,
                                actionItem,
                                proOrMat
                              )
                            "
                            v-bind:key="`add_action_item_${actionKey}`"
                            close
                            @click:close="
                              onClickRemoveAction(
                                surveyListItem,
                                component,
                                proOrMat,
                                actionItem.id
                              )
                            "
                          >
                            <div
                              class="px-2 py-2"
                              style="cursor: pointer;"
                              @click="
                                onClickEditAction(
                                  surveyListItem,
                                  component,
                                  proOrMat,
                                  actionItem.id,
                                  actionKey
                                )
                              "
                            >
                              {{ actionItem.label }}
                              <v-divider class="my-1"></v-divider>
                              {{ actionItem.comments }}
                            </div>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </template>
      <v-row class="text-center" v-if="surveyList && surveyList.length > 0">
        <v-col cols="12" sm="12" md="12">
          <v-btn
            color="success"
            dark
            class="mx-1 my-2"
            @click="onSaveClick"
            :loading="isSaving"
          >
            {{ $t("save") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <b-alert
      v-if="surveyList && surveyList.length === 0"
      show
      variant="info"
      class="mt-4"
    >
      <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
      cargando datos...
    </b-alert>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { mapGetters } from "vuex";
import moment from "moment";
import { logInfo, logError } from "@/utils";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import sessionTagLabels from "@/components/sessionTagLabels";
import SurveyQuestion from "./question";
import { QUESTION_TYPES } from "@/views/surveys/models.js";

export default {
  name: "posbrandsurvey",
  props: ["brandId"],
  components: {
    sessionTagLabels,
    SurveyQuestion,
    CoolLightBox,
  },
  data: function() {
    return {
      QUESTION_TYPES: QUESTION_TYPES,
      sessionId: null,
      worksessionPosId: null,
      posSession: null,
      worksessionPosBrands: [],
      selectedBrandId: null,
      selectedBrand: null,
      surveyList: [],
      surveyQuestionList: [],
      filterCriteria: {
        surveyId: null,
        surveyComponentId: null,
        surveyProOrMatId: null,
      },

      worksessionSurveyResponse: null,
      worksessionPosBrandId: null,
      posSessionChainCriteria: null,
      detectedSurveyChainIdForPuncDetails: null,
      surveyChains: null,
      posBrandPSObjective: 0,
      posBrandPSLastVisit: 0,

      isSaving: false,
      selected_image: null,
      selected_promatItem: null,
      showImageDialog: false,

      showAddActionDialog: false,
      selectedAddActionItem: null,
      surveyActionsForBrand: [],
      worksession_survey_actions: [],

      isVisibility: {},
      isVisibilityActions: {},
    };
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["user"]),
    titleOfAddActionDialog() {
      if (
        this.selectedAddActionItem &&
        this.selectedAddActionItem?.actionKey >= 0
      ) {
        return this.$t("edititem");
      } else {
        return this.$t("Add Action");
      }
    },
    filterableSurveyList() {
      let survey_list = [];
      if (this.surveyList && this.surveyList.length > 0) {
        survey_list = this.surveyList.map((el) => {
          return {
            id: el.id,
            name: el.name,
          };
        });
      }
      return survey_list;
    },
    filterableSurveyComponentList() {
      let component_list = [];
      if (this.filterCriteria["surveyId"]) {
        let foundSurvey = this.surveyList.find(
          (el) => el.id === this.filterCriteria["surveyId"]
        );
        if (foundSurvey) {
          component_list = foundSurvey.survey_components.map((el) => {
            return {
              id: el.id,
              name: el.name,
              assignType: el.assignType,
            };
          });
        }
      }
      return component_list;
    },
    assignTypeForSelectedComponent() {
      let assignType = "";
      if (this.filterCriteria["surveyComponentId"]) {
        let foundSurveyComponent = this.filterableSurveyComponentList.find(
          (el) => el.id === this.filterCriteria["surveyComponentId"]
        );
        if (foundSurveyComponent) {
          assignType = foundSurveyComponent.assignType;
        }
      }
      return assignType;
    },
    filterableProOrMatList() {
      let dataList = [];
      if (this.filterCriteria["surveyId"]) {
        let foundSurvey = this.surveyList.find(
          (el) => el.id === this.filterCriteria["surveyId"]
        );
        if (foundSurvey) {
          if (this.assignTypeForSelectedComponent === "PRODUCT") {
            dataList =
              foundSurvey.subProductMaterialsChainsThisSurvey["products"];
          } else if (this.assignTypeForSelectedComponent === "MATERIAL") {
            dataList =
              foundSurvey.subProductMaterialsChainsThisSurvey["materials"];
          }
        }
      }
      return dataList;
    },
    displayPosBrandPSLastVisit() {
      let calcedValue = 0;
      for (const [surveyId, value] of Object.entries(
        this.posBrandPSLastVisit
      )) {
        calcedValue += value;
      }
      return calcedValue;
    },
  },
  methods: {
    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },
    getImageUri(image) {
      return ApiService.getImageUri(image);
    },
    async onClickSelectedBrand(currentSelectedBrandId) {
      this.surveyList = [];
      await this.init(currentSelectedBrandId);
    },
    async onChangeSurvey() {
      this.filterCriteria["surveyComponentId"] = null;
      this.filterCriteria["surveyProOrMatId"] = null;
    },
    async onChangeSurveyComponent() {
      this.filterCriteria["surveyProOrMatId"] = null;
    },
    async onChangeSurveyComponentProOrMat() {},

    async onChangeFilterSurveys() {
      console.log("filterCriteria - ", this.filterCriteria);
      for (const surveyListItem of this.surveyList) {
        for (const component of surveyListItem.survey_components) {
          let proOrMatList = this.getProOrMatList(surveyListItem, component);
          for (const proOrMat of proOrMatList) {
            let proOrMatId = proOrMat ? proOrMat.id : null;
            let isVisibleProOrMat = false;

            let actionList = this.getAddedActions(
              surveyListItem,
              component,
              proOrMat
            );
            let isVisibleActionProOrMat = false;
            for (const actionItem of actionList) {
              let isVisibleActionItem = true;
              if (
                Object.keys(this.filterCriteria).includes("no_answers") &&
                this.filterCriteria["no_answers"]
              ) {
                if (actionItem.comments && actionItem.comments !== "") {
                  isVisibleActionItem &= false;
                }
              }
              this.isVisibilityActions[surveyListItem.id]["components"][
                component.id
              ]["proOrMatList"][proOrMatId]["actions"][
                actionItem.surveyActionId
              ].isShow = isVisibleActionItem;

              isVisibleActionProOrMat =
                isVisibleActionProOrMat || isVisibleActionItem;
            }
            this.isVisibilityActions[surveyListItem.id]["components"][
              component.id
            ]["proOrMatList"][proOrMatId]["isShow"] = isVisibleActionProOrMat;

            for (const question of component.survey_questions) {
              let isVisible = true;
              if (
                Object.keys(this.filterCriteria).includes("isMandatory") &&
                this.filterCriteria["isMandatory"]
              ) {
                if (!question.isMandatory) {
                  isVisible &= false;
                }
              } else {
                isVisible &= true;
              }

              if (
                Object.keys(this.filterCriteria).includes("zero_points") &&
                this.filterCriteria["zero_points"]
              ) {
                let current_point = this.getInitialQuestionValue(
                  surveyListItem.id,
                  question.id,
                  component.assignType,
                  proOrMatId,
                  1
                );
                if (current_point !== 0) {
                  isVisible &= false;
                }
                let optionScores = this.getOptionScores(
                  question,
                  component.assignType,
                  proOrMatId
                );
                if (
                  !optionScores ||
                  (optionScores && !optionScores?.PSValues)
                ) {
                  isVisible &= false;
                }
              } else {
                isVisible &= true;
              }

              if (
                Object.keys(this.filterCriteria).includes("actions") &&
                this.filterCriteria["actions"]
              ) {
                if (
                  [QUESTION_TYPES.ACTION].includes(
                    question?.survey_question_type.type
                  )
                ) {
                  let actionsOptionDetails = this.getOptionDetails(question.id);
                  let actionItems = [];
                  if (actionsOptionDetails && actionsOptionDetails?.values) {
                    for (const [optionId, optionItem] of Object.entries(
                      actionsOptionDetails?.values
                    )) {
                      if (optionItem?.isAction) {
                        actionItems.push(optionId);
                      }
                    }
                  }
                  console.log("actionItems - ", actionItems);
                  if (
                    Object.keys(this.worksessionSurveyResponse.details)
                      .map((el) => parseInt(el))
                      .includes(surveyListItem.id)
                  ) {
                    let currentSurveyItem = this.worksessionSurveyResponse
                      .details[surveyListItem.id];
                    if (
                      Object.keys(currentSurveyItem?.surveyQuestionDetails)
                        .map((el) => parseInt(el))
                        .includes(question.id)
                    ) {
                      let currentSurveyQuestionItem =
                        currentSurveyItem?.surveyQuestionDetails[question.id];
                      console.log(
                        "currentSurveyQuestionItem - ",
                        currentSurveyQuestionItem
                      );
                      if (
                        currentSurveyQuestionItem.assignType !== "OTHERS" &&
                        proOrMatId &&
                        Object.keys(currentSurveyQuestionItem?.details)
                          .map((el) => parseInt(el))
                          .includes(proOrMatId)
                      ) {
                        console.log(
                          "currentSurveyQuestionItem - ",
                          currentSurveyQuestionItem
                        );
                        if (Array.isArray(currentSurveyQuestionItem.value)) {
                          let selectedActionItems = currentSurveyQuestionItem?.details[
                            proOrMatId
                          ].value.filter((el) => actionItems.includes(el));
                          if (selectedActionItems.length === 0)
                            isVisible &= false;
                        } else {
                          if (
                            !actionItems.includes(
                              currentSurveyQuestionItem?.details[proOrMatId]
                                .value
                            )
                          ) {
                            isVisible &= false;
                          }
                        }
                      } else {
                        if (Array.isArray(currentSurveyQuestionItem.value)) {
                          let selectedActionItems = currentSurveyQuestionItem.value.filter(
                            (el) => actionItems.includes(el)
                          );
                          if (selectedActionItems.length === 0)
                            isVisible = false;
                        } else {
                          if (
                            actionItems.includes(
                              currentSurveyQuestionItem.value
                            )
                          ) {
                            isVisible &= false;
                          }
                        }
                      }
                    }
                  }
                } else {
                  isVisible &= false;
                }
              } else {
                isVisible &= true;
              }

              if (
                (Object.keys(this.filterCriteria).includes("no_answers") &&
                  this.filterCriteria["no_answers"]) ||
                (Object.keys(this.filterCriteria).includes("answers") &&
                  this.filterCriteria["answers"])
              ) {
                let isFilterAnswer = false;
                if (
                  Object.keys(this.filterCriteria).includes("answers") &&
                  this.filterCriteria["answers"]
                ) {
                  isFilterAnswer = true;
                }
                if (
                  Object.keys(this.worksessionSurveyResponse.details)
                    .map((el) => parseInt(el))
                    .includes(surveyListItem.id)
                ) {
                  let currentSurveyItem = this.worksessionSurveyResponse
                    .details[surveyListItem.id];
                  if (
                    Object.keys(currentSurveyItem?.surveyQuestionDetails)
                      .map((el) => parseInt(el))
                      .includes(question.id)
                  ) {
                    let currentSurveyQuestionItem =
                      currentSurveyItem?.surveyQuestionDetails[question.id];
                    if (
                      currentSurveyQuestionItem.assignType !== "OTHERS" &&
                      proOrMatId &&
                      Object.keys(currentSurveyQuestionItem?.details)
                        .map((el) => parseInt(el))
                        .includes(proOrMatId)
                    ) {
                      let surveyQuestionIsCompleted = this.isValueFullfilled(
                        currentSurveyQuestionItem.questionType,
                        currentSurveyQuestionItem?.details[proOrMatId]
                      );
                      if (isFilterAnswer) {
                        if (!surveyQuestionIsCompleted) isVisible &= false;
                      } else {
                        if (surveyQuestionIsCompleted) isVisible &= false;
                      }
                    } else {
                      let surveyQuestionIsCompleted = this.isValueFullfilled(
                        currentSurveyQuestionItem.questionType,
                        currentSurveyQuestionItem
                      );
                      if (isFilterAnswer) {
                        if (!surveyQuestionIsCompleted) isVisible &= false;
                      } else {
                        if (surveyQuestionIsCompleted) isVisible &= false;
                      }
                    }
                  }
                }
              }

              this.isVisibility[surveyListItem.id]["components"][component.id][
                "proOrMatList"
              ][proOrMatId]["questions"][question.id].isShow = isVisible;

              isVisibleProOrMat = isVisibleProOrMat || isVisible;
            }
            this.isVisibility[surveyListItem.id]["components"][component.id][
              "proOrMatList"
            ][proOrMatId]["isShow"] = isVisibleProOrMat;
          }
        }
      }
    },
    async onClickAddActions(surveyItem, surveyComponentItem, proOrMatItem) {
      this.selectedAddActionItem = {
        surveyItem,
        surveyComponentItem,
        proOrMatItem,
        surveyActionId: null,
        comments: null,
      };
      this.showAddActionDialog = true;
    },
    async onClickEditAction(
      surveyItem,
      surveyComponentItem,
      proOrMatItem,
      surveyActionId,
      actionKey
    ) {
      let { surveyActionItems } = this.getSurveyActionItem(
        surveyItem,
        surveyComponentItem,
        proOrMatItem
      );
      let found = surveyActionItems.find(
        (el) => el.surveyActionId === surveyActionId
      );
      if (found) {
        this.selectedAddActionItem = {
          surveyItem,
          surveyComponentItem,
          proOrMatItem,
          surveyActionId: found.surveyActionId,
          comments: found.comments,
          actionKey,
        };
        this.showAddActionDialog = true;
      }
    },
    closeAddActionsDialog() {
      this.showAddActionDialog = false;
      this.$nextTick(() => {
        this.selectedAddActionItem = null;
      });
    },
    getSurveyActionItem(surveyItem, surveyComponentItem, proOrMatItem) {
      let surveyActionItems = [];
      if (
        Object.keys(this.worksession_survey_actions)
          .map((el) => parseInt(el))
          .includes(surveyItem.id) &&
        Object.keys(this.worksession_survey_actions[surveyItem.id])
          .map((el) => parseInt(el))
          .includes(surveyComponentItem.id)
      ) {
        const { assignType } = surveyComponentItem;
        if (assignType !== "OTHERS" && proOrMatItem) {
          if (
            Object.keys(
              this.worksession_survey_actions[surveyItem.id][
                surveyComponentItem.id
              ]["details"]
            )
              .map((el) => parseInt(el))
              .includes(proOrMatItem.id)
          ) {
            surveyActionItems = this.worksession_survey_actions[surveyItem.id][
              surveyComponentItem.id
            ]["details"][proOrMatItem.id];
          }
        } else {
          surveyActionItems = this.worksession_survey_actions[surveyItem.id][
            surveyComponentItem.id
          ]["details"];
        }
      }
      return { surveyActionItems };
    },
    getAddedActions(surveyItem, surveyComponentItem, proOrMatItem) {
      let { surveyActionItems } = this.getSurveyActionItem(
        surveyItem,
        surveyComponentItem,
        proOrMatItem
      );
      let addedSurveyActionItems = [];
      if (surveyActionItems.length > 0) {
        addedSurveyActionItems = surveyActionItems.map((el) => {
          let pushItem = { ...el };
          let foundSurveyDetailItem = this.surveyActionsForBrand.find(
            (surveyActionsForBrandEl) =>
              surveyActionsForBrandEl.id === el.surveyActionId
          );
          if (foundSurveyDetailItem) {
            pushItem = { ...pushItem, ...foundSurveyDetailItem };
          }
          return pushItem;
        });
      }
      return addedSurveyActionItems;
    },
    onClickRemoveAction(
      surveyItem,
      surveyComponentItem,
      proOrMatItem,
      surveyActionId
    ) {
      if (
        Object.keys(this.worksession_survey_actions)
          .map((el) => parseInt(el))
          .includes(surveyItem.id) &&
        Object.keys(this.worksession_survey_actions[surveyItem.id])
          .map((el) => parseInt(el))
          .includes(surveyComponentItem.id)
      ) {
        const { assignType } = surveyComponentItem;
        if (assignType !== "OTHERS" && proOrMatItem) {
          if (
            Object.keys(
              this.worksession_survey_actions[surveyItem.id][
                surveyComponentItem.id
              ]["details"]
            )
              .map((el) => parseInt(el))
              .includes(proOrMatItem.id)
          ) {
            this.worksession_survey_actions[surveyItem.id][
              surveyComponentItem.id
            ]["details"][proOrMatItem.id] = this.worksession_survey_actions[
              surveyItem.id
            ][surveyComponentItem.id]["details"][proOrMatItem.id].filter(
              (el) => el.surveyActionId !== surveyActionId
            );
          }
        } else {
          this.worksession_survey_actions[surveyItem.id][
            surveyComponentItem.id
          ]["details"] = this.worksession_survey_actions[surveyItem.id][
            surveyComponentItem.id
          ]["details"].filter((el) => el.surveyActionId !== surveyActionId);
        }
      }
    },
    isCheckDuplicationForAddSurveyActions(selectedAddActionItem) {
      let isDuplication = false;
      const {
        surveyItem,
        surveyComponentItem,
        proOrMatItem,
        surveyActionId,
        actionKey,
      } = this.selectedAddActionItem;
      if (
        Object.keys(this.worksession_survey_actions)
          .map((el) => parseInt(el))
          .includes(surveyItem.id) &&
        Object.keys(this.worksession_survey_actions[surveyItem.id])
          .map((el) => parseInt(el))
          .includes(surveyComponentItem.id)
      ) {
        const { assignType } = surveyComponentItem;
        if (assignType !== "OTHERS" && proOrMatItem) {
          if (
            Object.keys(
              this.worksession_survey_actions[surveyItem.id][
                surveyComponentItem.id
              ]["details"]
            )
              .map((el) => parseInt(el))
              .includes(proOrMatItem.id)
          ) {
            let found = this.worksession_survey_actions[surveyItem.id][
              surveyComponentItem.id
            ]["details"][proOrMatItem.id].filter(
              (el, index) =>
                index !== actionKey && el.surveyActionId === surveyActionId
            );
            if (found.length > 0) isDuplication = true;
          }
        } else {
          let found = this.worksession_survey_actions[surveyItem.id][
            surveyComponentItem.id
          ]["details"].filter(
            (el, index) =>
              index !== actionKey && el.surveyActionId === surveyActionId
          );
          if (found.length > 0) isDuplication = true;
        }
      }
      return isDuplication;
    },
    onClickSaveAddActions(selectedAddActionItem) {
      let isDuplication = this.isCheckDuplicationForAddSurveyActions(
        selectedAddActionItem
      );
      if (isDuplication) {
        logError("No puede agregar la acción. La acción ya ha sido añadida.");
        return;
      }
      const {
        surveyItem,
        surveyComponentItem,
        proOrMatItem,
        surveyActionId,
        comments,
        actionKey,
      } = selectedAddActionItem;
      let pushItem = { surveyActionId, comments };
      if (
        Object.keys(this.worksession_survey_actions)
          .map((el) => parseInt(el))
          .includes(surveyItem.id) &&
        Object.keys(this.worksession_survey_actions[surveyItem.id])
          .map((el) => parseInt(el))
          .includes(surveyComponentItem.id)
      ) {
        const { assignType } = surveyComponentItem;
        if (assignType !== "OTHERS" && proOrMatItem) {
          if (
            Object.keys(
              this.worksession_survey_actions[surveyItem.id][
                surveyComponentItem.id
              ]["details"]
            )
              .map((el) => parseInt(el))
              .includes(proOrMatItem.id)
          ) {
            if (!isNaN(actionKey)) {
              this.worksession_survey_actions[surveyItem.id][
                surveyComponentItem.id
              ]["details"][proOrMatItem.id][actionKey] = pushItem;
            } else {
              this.worksession_survey_actions[surveyItem.id][
                surveyComponentItem.id
              ]["details"][proOrMatItem.id].push(pushItem);
            }
          }
        } else {
          if (!isNaN(actionKey)) {
            this.worksession_survey_actions[surveyItem.id][
              surveyComponentItem.id
            ]["details"][actionKey] = pushItem;
          } else {
            this.worksession_survey_actions[surveyItem.id][
              surveyComponentItem.id
            ]["details"].push(pushItem);
          }
        }
      }
      this.showAddActionDialog = false;
      this.$nextTick(() => {
        this.selectedAddActionItem = null;
      });
    },
    onItemShowImageDialog(assignType, proOrMatItem) {
      this.selected_promatItem = proOrMatItem;
      let thumbnail_url = this.getProOrMatImageComponent(
        assignType,
        proOrMatItem,
        false
      );
      this.selected_image = thumbnail_url;
      this.showImageDialog = true;
    },
    closeImageDialog() {
      this.showImageDialog = false;
      this.$nextTick(() => {
        this.selected_image = null;
      });
    },
    async getSessionSurveyData() {
      this.filterCriteria = {
        surveyId: null,
        surveyComponentId: null,
        surveyProOrMatId: null,
      };
      const {
        survey_list,
        surveyQuestionList,
        worksession_survey_response,
        surveyChains,
        worksessionPosBrandId,
        posBrandPSObjective,
        posBrandPSLastVisit,
        surveyActionsForBrand,
        worksession_survey_actions,
      } = await ApiService.post(
        `worksession/getSessionSurveyData/${this.worksessionPosId}/${this.selectedBrandId}`,
        { posSessionChainCriteria: this.posSessionChainCriteria }
      );
      this.surveyList = survey_list;
      this.filteredSurveyList = this.surveyList;
      this.surveyQuestionList = surveyQuestionList;
      this.worksessionSurveyResponse = worksession_survey_response;
      this.surveyChains = surveyChains;
      this.worksessionPosBrandId = worksessionPosBrandId;
      this.posBrandPSObjective = posBrandPSObjective;
      this.posBrandPSLastVisit = posBrandPSLastVisit;
      this.surveyActionsForBrand = surveyActionsForBrand;
      this.worksession_survey_actions = worksession_survey_actions;
      if (
        this.surveyChains &&
        this.surveyChains.length > 0 &&
        this.posSessionChainCriteria
      ) {
        let foundSurveyChainItem = this.surveyChains.find(
          (el) =>
            el.chainId === this.posSessionChainCriteria.chainId &&
            el.subChainId === this.posSessionChainCriteria.subChainId &&
            el.sub2ChainId === this.posSessionChainCriteria.sub2ChainId
        );
        if (foundSurveyChainItem) {
          this.detectedSurveyChainIdForPuncDetails = foundSurveyChainItem.id;
        }
      } else {
        this.detectedSurveyChainIdForPuncDetails = null;
      }
      this.initVisibility();
      this.initFilterCriteria();
      this.calcPoints();
    },
    isValueFullfilled(questionType, dataItem) {
      let isFullFilled = true;
      if ([QUESTION_TYPES.PHOTO].includes(questionType)) {
        if (!dataItem.value && !dataItem?.attachments) {
          isFullFilled = false;
        }
      } else {
        if (Array.isArray(dataItem.value) && dataItem.value.length === 0) {
          isFullFilled = false;
        } else if (
          dataItem.value === null ||
          dataItem.value === undefined ||
          dataItem.value === ""
        ) {
          isFullFilled = false;
        }
      }
      return isFullFilled;
    },
    getPerfectStoreDetails(surveyQuestionId) {
      let perfectData = null;
      let foundSurveyQuestion = this.surveyQuestionList.find(
        (el) => el.id === parseInt(surveyQuestionId)
      );
      if (foundSurveyQuestion) {
        perfectData = foundSurveyQuestion.perfectStoreDetails;
      }
      return perfectData;
    },
    getOptionDetails(surveyQuestionId) {
      let optionData = null;
      let foundSurveyQuestion = this.surveyQuestionList.find(
        (el) => el.id === parseInt(surveyQuestionId)
      );
      if (foundSurveyQuestion) {
        optionData = foundSurveyQuestion.options;
      }
      return optionData;
    },
    setMaximumPSValueForTheQuestion(
      assignTypeValue,
      surveyId,
      surveyQuestionId,
      surveyProOrMatId,
      proOrMatItem,
      maximumPSValue
    ) {
      if (
        Object.keys(this.worksessionSurveyResponse["details"])
          .map((el) => parseInt(el))
          .includes(surveyId)
      ) {
        if (
          Object.keys(
            this.worksessionSurveyResponse["details"][surveyId][
              "surveyQuestionDetails"
            ]
          )
            .map((el) => parseInt(el))
            .includes(surveyQuestionId)
        ) {
          if (
            assignTypeValue !== "OTHERS" &&
            Object.keys(
              this.worksessionSurveyResponse["details"][surveyId][
                "surveyQuestionDetails"
              ][surveyQuestionId]?.details
            )
              // .map((el) => parseInt(el))
              .includes(surveyProOrMatId)
          ) {
            this.worksessionSurveyResponse["details"][surveyId][
              "surveyQuestionDetails"
            ][surveyQuestionId].details[
              surveyProOrMatId
            ].maximumPSValue = maximumPSValue;
          } else {
            this.worksessionSurveyResponse["details"][surveyId][
              "surveyQuestionDetails"
            ][surveyQuestionId].maximumPSValue = maximumPSValue;
          }
        }
      }
    },
    getScoreOfCurrentQuestion(
      assignType,
      questionType,
      surveyId,
      surveyQuestionId,
      proOrMatId,
      proOrMatItem,
      surveyChainId,
      currentPSDetails,
      currentPSOptions
    ) {
      let calcValueOfScore = 0;
      if (
        currentPSDetails &&
        assignType === currentPSDetails.assignType &&
        currentPSDetails?.values &&
        currentPSDetails?.values[assignType]
      ) {
        if (proOrMatId) {
          let PSDetailsOfCurrentProOrMat = null;
          if (
            Object.keys(currentPSDetails?.values[assignType]).includes(
              proOrMatId
            )
          ) {
            PSDetailsOfCurrentProOrMat =
              currentPSDetails?.values[assignType][proOrMatId];
          } else {
            PSDetailsOfCurrentProOrMat =
              currentPSDetails?.values[assignType]["default"];
          }
          let PSDetailsOfCurrentSurveyChain = null;
          if (!PSDetailsOfCurrentProOrMat) {
            PSDetailsOfCurrentProOrMat = {};
          }
          if (
            Object.keys(PSDetailsOfCurrentProOrMat)
              .map((el) => parseInt(el))
              .includes(surveyChainId)
          ) {
            PSDetailsOfCurrentSurveyChain =
              PSDetailsOfCurrentProOrMat[surveyChainId];
          } else {
            PSDetailsOfCurrentSurveyChain =
              PSDetailsOfCurrentProOrMat["default"];
          }
          if (!PSDetailsOfCurrentSurveyChain) {
            PSDetailsOfCurrentSurveyChain = {};
          }
          if (PSDetailsOfCurrentSurveyChain) {
            let maximumPSValue = -99999;
            for (const [optionKey, value] of Object.entries(
              PSDetailsOfCurrentSurveyChain
            )) {
              if (maximumPSValue < parseFloat(value)) {
                maximumPSValue = parseFloat(value);
              }
            }
            if (maximumPSValue === -99999) maximumPSValue = null;
            this.setMaximumPSValueForTheQuestion(
              assignType,
              parseInt(surveyId),
              parseInt(surveyQuestionId),
              proOrMatId,
              proOrMatItem,
              maximumPSValue
            );
          }
          if (
            [
              QUESTION_TYPES.BOOL,
              QUESTION_TYPES.RADIO,
              QUESTION_TYPES.CHECKBOX,
              QUESTION_TYPES.SELECT,
              QUESTION_TYPES.ACTION,
            ].includes(questionType)
          ) {
            if (Array.isArray(proOrMatItem.value)) {
              let selectedOptionKeys = Object.keys(
                PSDetailsOfCurrentSurveyChain
              ).filter((el) => proOrMatItem.value.includes(el));
              for (const optionKey of selectedOptionKeys) {
                calcValueOfScore += parseFloat(
                  PSDetailsOfCurrentSurveyChain[optionKey]
                );
              }
            } else {
              if (
                Object.keys(PSDetailsOfCurrentSurveyChain)
                  .map((el) => parseInt(el))
                  .includes(parseInt(proOrMatItem.value))
              ) {
                calcValueOfScore +=
                  PSDetailsOfCurrentSurveyChain[proOrMatItem.value];
              }
            }
          } else if (
            [
              QUESTION_TYPES.MONEY,
              QUESTION_TYPES.DECIMAL,
              QUESTION_TYPES.NUMBER,
            ].includes(questionType)
          ) {
            if (currentPSOptions && currentPSOptions?.ranges) {
              let selectedRangeKey = null;
              for (const [rangeKey, rangeItem] of Object.entries(
                currentPSOptions.ranges
              )) {
                if (
                  parseFloat(proOrMatItem.value) >=
                    parseFloat(rangeItem.fromValue) &&
                  parseFloat(proOrMatItem.value) <=
                    parseFloat(rangeItem.toValue)
                ) {
                  selectedRangeKey = parseInt(rangeKey);
                  break;
                }
              }
              if (
                typeof selectedRangeKey === "number" &&
                PSDetailsOfCurrentSurveyChain[selectedRangeKey]
              ) {
                calcValueOfScore +=
                  PSDetailsOfCurrentSurveyChain[selectedRangeKey];
              }
            }
          } else {
            if (this.isValueFullfilled(questionType, proOrMatItem)) {
              if (PSDetailsOfCurrentSurveyChain?.otherValue) {
                calcValueOfScore = PSDetailsOfCurrentSurveyChain?.otherValue;
              }
            }
          }
        } else {
          let PSDetailsOfCurrentOptions = [];
          if (
            [
              QUESTION_TYPES.BOOL,
              QUESTION_TYPES.RADIO,
              QUESTION_TYPES.CHECKBOX,
              QUESTION_TYPES.SELECT,
              QUESTION_TYPES.ACTION,
            ].includes(questionType)
          ) {
            let selectedOptionKeys = [];
            if (Array.isArray(proOrMatItem.value)) {
              selectedOptionKeys = proOrMatItem.value;
            } else {
              selectedOptionKeys = [proOrMatItem.value];
            }
            for (const [optionId, optionItem] of Object.entries(
              currentPSDetails?.values[assignType]
            )) {
              if (selectedOptionKeys.includes(optionId)) {
                PSDetailsOfCurrentOptions.push(optionItem);
              }
            }
          } else if (
            [
              QUESTION_TYPES.MONEY,
              QUESTION_TYPES.DECIMAL,
              QUESTION_TYPES.NUMBER,
            ].includes(questionType)
          ) {
            if (currentPSOptions && currentPSOptions?.ranges) {
              let selectedRangeKey = null;
              for (const [rangeKey, rangeItem] of Object.entries(
                currentPSOptions.ranges
              )) {
                if (
                  parseFloat(proOrMatItem.value) >=
                    parseFloat(rangeItem.fromValue) &&
                  parseFloat(proOrMatItem.value) <=
                    parseFloat(rangeItem.toValue)
                ) {
                  selectedRangeKey = parseInt(rangeKey);
                  break;
                }
              }
              if (typeof selectedRangeKey === "number") {
                PSDetailsOfCurrentOptions.push(
                  currentPSDetails?.values[assignType][selectedRangeKey]
                );
              }
            }
          } else {
            if (this.isValueFullfilled(questionType, proOrMatItem)) {
              if (
                Object.keys(currentPSDetails?.values[assignType]).includes(
                  "default"
                )
              ) {
                PSDetailsOfCurrentOptions.push(
                  currentPSDetails?.values[assignType]["default"]
                );
              }
            }
          }
          let PSDetailsOfCurrentSurveyChain = [];
          for (const currentOptionsPSDetailItem of PSDetailsOfCurrentOptions) {
            if (
              Object.keys(currentOptionsPSDetailItem)
                .map((el) => parseInt(el))
                .includes(surveyChainId)
            ) {
              PSDetailsOfCurrentSurveyChain.push(
                currentOptionsPSDetailItem[surveyChainId]
              );
            } else {
              PSDetailsOfCurrentSurveyChain.push(
                PSDetailsOfCurrentOptions["default"]
              );
            }
          }
          PSDetailsOfCurrentSurveyChain.map((el) => {
            if (el?.otherValue) {
              calcValueOfScore += el.otherValue;
            }
          });
          if (currentPSDetails && surveyChainId) {
            let maximumPSValue = -99999;
            for (const [optionKey, optionItem] of Object.entries(
              currentPSDetails?.values[assignType]
            )) {
              if (
                optionItem &&
                Object.keys(optionItem)
                  .map((el) => parseInt(el))
                  .includes(surveyChainId)
              ) {
                if (
                  parseFloat(optionItem[surveyChainId]?.otherValue) >
                  maximumPSValue
                ) {
                  maximumPSValue = parseFloat(
                    optionItem[surveyChainId]?.otherValue
                  );
                }
              }
            }
            if (maximumPSValue === -99999) maximumPSValue = null;
            this.setMaximumPSValueForTheQuestion(
              assignType,
              parseInt(surveyId),
              parseInt(surveyQuestionId),
              proOrMatId,
              proOrMatItem,
              maximumPSValue
            );
          }
        }
      }
      return calcValueOfScore;
    },
    calcPoints() {
      let totalSurveyBrandScore = 0;
      let isCompletedSurveyBrand = true;
      for (const [surveyId, surveyItem] of Object.entries(
        this.worksessionSurveyResponse.details
      )) {
        let totalSurveyScore = 0;
        let isCompletedSurvey = true;
        let currentSurveyChainId = null;
        let foundSurveyChainItem = this.surveyChains.find(
          (el) =>
            el.chainId === this.posSessionChainCriteria.chainId &&
            el.subChainId === this.posSessionChainCriteria.subChainId &&
            el.sub2ChainId === this.posSessionChainCriteria.sub2ChainId
        );
        if (foundSurveyChainItem) {
          currentSurveyChainId = foundSurveyChainItem.id;
        }
        for (const [surveyQuestionId, surveyQuestionItem] of Object.entries(
          surveyItem?.surveyQuestionDetails
        )) {
          let totalSurveyQuestionScore = 0;
          let isCompletedSurveyQuestion = true;
          const { assignType, questionType } = surveyQuestionItem;
          let currentPSDetails = this.getPerfectStoreDetails(surveyQuestionId);
          let currentPSOptions = this.getOptionDetails(surveyQuestionId);
          if (currentPSDetails) {
            if (
              assignType !== "OTHERS" &&
              Object.keys(surveyQuestionItem?.details).length > 0
            ) {
              for (const [proOrMatId, proOrMatItem] of Object.entries(
                surveyQuestionItem.details
              )) {
                // decision the score for the surveyquestion/promat
                // proOrMatId - productId or materialId
                // surveyItem.surveyChainId - the chain for this survey
                // surveyQuestionItem.questionType
                // proOrMatItem.value - current value
                let surveyQuestionProOrMatScore = this.getScoreOfCurrentQuestion(
                  assignType,
                  questionType,
                  surveyId,
                  surveyQuestionId,
                  proOrMatId,
                  proOrMatItem,
                  currentSurveyChainId,
                  currentPSDetails,
                  currentPSOptions
                );
                this.worksessionSurveyResponse["details"][surveyId][
                  "surveyQuestionDetails"
                ][surveyQuestionId]["details"][
                  proOrMatId
                ].score = surveyQuestionProOrMatScore;
                totalSurveyQuestionScore += surveyQuestionProOrMatScore;

                let surveyQuestionIsCompleted = this.isValueFullfilled(
                  questionType,
                  proOrMatItem
                );
                isCompletedSurveyQuestion =
                  isCompletedSurveyQuestion && surveyQuestionIsCompleted;
              }
            } else if (
              assignType !== "OTHERS" &&
              Object.keys(surveyQuestionItem?.details).length === 0
            ) {
              let surveyQuestionProOrMatScore = this.getScoreOfCurrentQuestion(
                assignType,
                questionType,
                surveyId,
                surveyQuestionId,
                "default",
                surveyQuestionItem,
                currentSurveyChainId,
                currentPSDetails,
                currentPSOptions
              );
              this.worksessionSurveyResponse["details"][surveyId][
                "surveyQuestionDetails"
              ][surveyQuestionId].score = surveyQuestionProOrMatScore;
              totalSurveyQuestionScore += surveyQuestionProOrMatScore;

              let surveyQuestionIsCompleted = this.isValueFullfilled(
                questionType,
                surveyQuestionItem
              );
              isCompletedSurveyQuestion =
                isCompletedSurveyQuestion && surveyQuestionIsCompleted;
            } else {
              let surveyQuestionProOrMatScore = this.getScoreOfCurrentQuestion(
                assignType,
                questionType,
                surveyId,
                surveyQuestionId,
                null,
                surveyQuestionItem,
                currentSurveyChainId,
                currentPSDetails,
                currentPSOptions
              );
              this.worksessionSurveyResponse["details"][surveyId][
                "surveyQuestionDetails"
              ][surveyQuestionId].score = surveyQuestionProOrMatScore;
              totalSurveyQuestionScore += surveyQuestionProOrMatScore;

              let surveyQuestionIsCompleted = this.isValueFullfilled(
                questionType,
                surveyQuestionItem
              );
              isCompletedSurveyQuestion =
                isCompletedSurveyQuestion && surveyQuestionIsCompleted;
            }
          }
          this.worksessionSurveyResponse["details"][surveyId][
            "surveyQuestionDetails"
          ][surveyQuestionId].score = totalSurveyQuestionScore;
          totalSurveyScore += totalSurveyQuestionScore;
          isCompletedSurvey = isCompletedSurvey && isCompletedSurveyQuestion;
        }
        let isRemember = this.isRememberForTheSurvey(surveyId);
        if (isRemember) {
          let lastVisitPtForCurrentSurvey = this.getPSLastVisitForTheSurvey(
            surveyId
          );
          totalSurveyScore += lastVisitPtForCurrentSurvey;
        }
        this.worksessionSurveyResponse["details"][
          surveyId
        ].score = totalSurveyScore;
        this.worksessionSurveyResponse["details"][
          surveyId
        ].isCompleted = isCompletedSurvey;
        totalSurveyBrandScore += totalSurveyScore;
        isCompletedSurveyBrand = isCompletedSurveyBrand && isCompletedSurvey;
      }
      this.worksessionSurveyResponse.surveyBrandScore = totalSurveyBrandScore;
      this.worksessionSurveyResponse.isCompletedSurveyBrand = isCompletedSurveyBrand;
    },
    isRememberForTheSurvey(surveyId) {
      let foundSurvey = this.surveyList.find(
        (el) => el.id === parseInt(surveyId)
      );
      if (foundSurvey) {
        return foundSurvey.isRemember;
      }
      return false;
    },
    getPSLastVisitForTheSurvey(surveyId) {
      if (Object.keys(this.posBrandPSLastVisit).includes(surveyId)) {
        return this.posBrandPSLastVisit[surveyId];
      }
      return 0;
    },
    initVisibility() {
      for (const surveyListItem of this.surveyList) {
        let isVisibleSurveyItem = {
          isShow: true,
          components: {},
        };
        let isVisibleActionSurveyItem = {
          isShow: true,
          components: {},
        };
        for (const component of surveyListItem.survey_components) {
          let isVisibleComponentItem = {
            isShow: true,
            proOrMatList: {},
          };
          let isVisibleActionComponentItem = {
            isShow: true,
            proOrMatList: {},
          };
          let proOrMatList = this.getProOrMatList(surveyListItem, component);
          for (const proOrMat of proOrMatList) {
            let isVisibleProOrMat = {
              isShow: true,
              questions: {},
            };
            let isVisibleActionsProOrMat = {
              isShow: true,
              actions: {},
            };
            let actionList = this.getAddedActions(
              surveyListItem,
              component,
              proOrMat
            );
            for (const actionItem of actionList) {
              let isVisibleActionItem = {
                isShow: true,
              };
              isVisibleActionsProOrMat["actions"][
                actionItem.surveyActionId
              ] = isVisibleActionItem;
            }
            for (const question of component.survey_questions) {
              let isVisibleQuestion = {
                isShow: true,
              };
              isVisibleProOrMat["questions"][question.id] = isVisibleQuestion;
            }
            if (proOrMat) {
              isVisibleComponentItem["proOrMatList"][
                proOrMat.id
              ] = isVisibleProOrMat;
              isVisibleActionComponentItem["proOrMatList"][
                proOrMat.id
              ] = isVisibleActionsProOrMat;
            } else {
              isVisibleComponentItem["proOrMatList"][null] = isVisibleProOrMat;
              isVisibleActionComponentItem["proOrMatList"][
                null
              ] = isVisibleActionsProOrMat;
            }
          }
          isVisibleSurveyItem.components[component.id] = isVisibleComponentItem;
          isVisibleActionSurveyItem.components[
            component.id
          ] = isVisibleActionComponentItem;
        }
        this.isVisibility[surveyListItem.id] = isVisibleSurveyItem;
        this.isVisibilityActions[surveyListItem.id] = isVisibleActionSurveyItem;
      }
    },
    initFilterCriteria() {
      if (
        !this.filterCriteria["surveyId"] &&
        this.filterableSurveyList.length === 1
      ) {
        this.filterCriteria["surveyId"] = this.filterableSurveyList[0].id;
      }
      if (
        this.filterCriteria["surveyId"] &&
        !this.filterCriteria["surveyComponentId"] &&
        this.filterableSurveyComponentList.length === 1
      ) {
        this.filterCriteria[
          "surveyComponentId"
        ] = this.filterableSurveyComponentList[0].id;
      }
    },
    getProOrMatList(surveyListItem, component) {
      if (component.assignType === "PRODUCT") {
        return surveyListItem["subProductMaterialsChainsThisSurvey"].products
          .length
          ? surveyListItem["subProductMaterialsChainsThisSurvey"].products
          : [null];
      } else if (component.assignType === "MATERIAL") {
        return surveyListItem["subProductMaterialsChainsThisSurvey"].materials
          .length
          ? surveyListItem["subProductMaterialsChainsThisSurvey"].materials
          : [null];
      } else {
        return [null];
      }
    },
    getProOrMatImageComponent(assignType, proOrMatItem, isThumbnail) {
      let thumbnail_url = null;
      if (proOrMatItem) {
        if (assignType === "PRODUCT" && proOrMatItem?.static) {
          thumbnail_url = isThumbnail
            ? this.getThumbnailUri(proOrMatItem.static)
            : this.getImageUri(proOrMatItem.static);
        } else if (assignType === "MATERIAL" && proOrMatItem?.materialPhoto) {
          thumbnail_url = isThumbnail
            ? this.getThumbnailUri(proOrMatItem.materialPhoto)
            : this.getImageUri(proOrMatItem.materialPhoto);
        }
      }
      return thumbnail_url;
    },
    getQuestionType(question) {
      let typeValue = "";
      if (question?.survey_question_type.type) {
        typeValue = question?.survey_question_type.type;
      }
      return typeValue;
    },
    getVisibilityProOrMat(surveyListItem, component, proOrMat) {
      let proOrMatId = proOrMat ? proOrMat.id : null;
      return this.isVisibility[surveyListItem.id]["components"][component.id][
        "proOrMatList"
      ][proOrMatId].isShow;
    },
    getVisibilityQuestion(surveyListItem, component, question, proOrMat) {
      let proOrMatId = proOrMat ? proOrMat.id : null;
      return this.isVisibility[surveyListItem.id]["components"][component.id][
        "proOrMatList"
      ][proOrMatId]["questions"][question.id].isShow;
    },
    getVisibilityActionProOrMat(surveyListItem, component, proOrMat) {
      let proOrMatId = proOrMat ? proOrMat.id : null;
      return this.isVisibilityActions[surveyListItem.id]["components"][
        component.id
      ]["proOrMatList"][proOrMatId].isShow;
    },
    getVisibilityAction(surveyListItem, component, actionItem, proOrMat) {
      let proOrMatId = proOrMat ? proOrMat.id : null;
      return this.isVisibilityActions[surveyListItem.id]["components"][
        component.id
      ]["proOrMatList"][proOrMatId]["actions"][actionItem.surveyActionId]
        .isShow;
    },
    getInitialQuestionValue(
      surveyId,
      surveyQuestionId,
      assignTypeValue,
      surveyProOrMatId,
      isGettingValueFlag
    ) {
      let returnValue = null;
      if (
        Object.keys(this.worksessionSurveyResponse["details"])
          .map((el) => parseInt(el))
          .includes(surveyId)
      ) {
        if (
          Object.keys(
            this.worksessionSurveyResponse["details"][surveyId][
              "surveyQuestionDetails"
            ]
          )
            .map((el) => parseInt(el))
            .includes(surveyQuestionId)
        ) {
          if (
            assignTypeValue !== "OTHERS" &&
            Object.keys(
              this.worksessionSurveyResponse["details"][surveyId][
                "surveyQuestionDetails"
              ][surveyQuestionId]?.details
            )
              .map((el) => parseInt(el))
              .includes(surveyProOrMatId)
          ) {
            if (isGettingValueFlag === 1) {
              // get initialized score
              returnValue = this.worksessionSurveyResponse["details"][surveyId][
                "surveyQuestionDetails"
              ][surveyQuestionId].details[surveyProOrMatId].score;
            } else if (isGettingValueFlag === 2) {
              // get initial value
              returnValue = this.worksessionSurveyResponse["details"][surveyId][
                "surveyQuestionDetails"
              ][surveyQuestionId].details[surveyProOrMatId].value;
            } else if (isGettingValueFlag === 3) {
              // get maximumPSValue value
              returnValue = this.worksessionSurveyResponse["details"][surveyId][
                "surveyQuestionDetails"
              ][surveyQuestionId].details[surveyProOrMatId].maximumPSValue;
            }
          } else {
            if (isGettingValueFlag === 1) {
              // get initialized score
              returnValue = this.worksessionSurveyResponse["details"][surveyId][
                "surveyQuestionDetails"
              ][surveyQuestionId].score;
            } else if (isGettingValueFlag === 2) {
              // get initial value
              returnValue = this.worksessionSurveyResponse["details"][surveyId][
                "surveyQuestionDetails"
              ][surveyQuestionId].value;
            } else if (isGettingValueFlag === 3) {
              // get maximumPSValue value
              returnValue = this.worksessionSurveyResponse["details"][surveyId][
                "surveyQuestionDetails"
              ][surveyQuestionId].maximumPSValue;
            }
          }
        }
      }
      return returnValue;
    },
    persistQuestionValue(
      result,
      questionType,
      surveyId,
      surveyQuestionId,
      assignTypeValue,
      surveyProOrMatId
    ) {
      let saveValue = result;
      if (
        [
          QUESTION_TYPES.MONEY,
          QUESTION_TYPES.DECIMAL,
          QUESTION_TYPES.NUMBER,
        ].includes(questionType)
      ) {
        if (result !== "") saveValue = parseFloat(result);
        else saveValue = null;
      }
      if (surveyProOrMatId) {
        this.worksessionSurveyResponse["details"][surveyId][
          "surveyQuestionDetails"
        ][surveyQuestionId].details[surveyProOrMatId].value = saveValue;
      } else {
        this.worksessionSurveyResponse["details"][surveyId][
          "surveyQuestionDetails"
        ][surveyQuestionId].value = saveValue;
      }
      this.calcPoints();
    },
    getOptionScores(question, assignTypeValue, surveyProOrMatId) {
      let retVal = {
        options: null,
        PSValues: null,
      };
      if (question?.options && question?.perfectStoreDetails) {
        if (assignTypeValue !== "OTHERS") {
          let proOrMatDetails = null;
          if (
            Object.keys(question.perfectStoreDetails.values[assignTypeValue])
              .map((el) => parseInt(el))
              .includes(surveyProOrMatId)
          ) {
            proOrMatDetails =
              question.perfectStoreDetails.values[assignTypeValue][
                surveyProOrMatId
              ];
          } else if (
            question.perfectStoreDetails.values[assignTypeValue]["default"]
          ) {
            proOrMatDetails =
              question.perfectStoreDetails.values[assignTypeValue]["default"];
          }
          if (proOrMatDetails) {
            let surveyChainDetails = null;
            if (
              Object.keys(proOrMatDetails)
                .map((el) => parseInt(el))
                .includes(this.detectedSurveyChainIdForPuncDetails)
            ) {
              surveyChainDetails =
                proOrMatDetails[this.detectedSurveyChainIdForPuncDetails];
            } else if (proOrMatDetails["default"]) {
              surveyChainDetails = proOrMatDetails["default"];
            }
            retVal.options = question.options;
            retVal.PSValues = surveyChainDetails;
          }
        } else {
          let surveyChainDetails = {};
          for (const [optKey, optItem] of Object.entries(
            question.perfectStoreDetails.values[assignTypeValue]
          )) {
            if (
              Object.keys(optItem)
                .map((el) => parseInt(el))
                .includes(this.detectedSurveyChainIdForPuncDetails)
            ) {
              surveyChainDetails[optKey] =
                optItem[this.detectedSurveyChainIdForPuncDetails];
            } else if (optItem["default"]) {
              surveyChainDetails[optKey] = optItem["default"];
            }
          }
          retVal.options = question.options;
          retVal.PSValues = surveyChainDetails;
        }
        return retVal;
      }
    },
    isAnsweredForAllMandatoryQuestions() {
      let isAnswered = true;
      for (const [surveyId, surveyItem] of Object.entries(
        this.worksessionSurveyResponse.details
      )) {
        for (const [surveyQuestionId, surveyQuestionItem] of Object.entries(
          surveyItem?.surveyQuestionDetails
        )) {
          if (!surveyQuestionItem.isMandatory) continue;
          if (
            surveyQuestionItem.assignType !== "OTHERS" &&
            Object.keys(surveyQuestionItem?.details).length > 0
          ) {
            for (const [proOrMatId, proOrMatItem] of Object.entries(
              surveyQuestionItem?.details
            )) {
              if (
                [QUESTION_TYPES.PHOTO].includes(surveyQuestionItem.questionType)
              ) {
                if (
                  (proOrMatItem.value === null ||
                    proOrMatItem.value === undefined) &&
                  !(
                    proOrMatItem?.attachments &&
                    proOrMatItem.attachments.length > 0
                  )
                ) {
                  isAnswered = false;
                  break;
                }
              } else {
                if (
                  proOrMatItem.value === null ||
                  proOrMatItem.value === undefined
                ) {
                  isAnswered = false;
                  break;
                }
              }
            }
          } else {
            if (
              [QUESTION_TYPES.PHOTO].includes(surveyQuestionItem.questionType)
            ) {
              if (
                (surveyQuestionItem.value === null ||
                  surveyQuestionItem.value === undefined) &&
                !(
                  surveyQuestionItem?.attachments &&
                  surveyQuestionItem.attachments.length > 0
                )
              ) {
                isAnswered = false;
                break;
              }
            } else {
              if (
                surveyQuestionItem.value === null ||
                surveyQuestionItem.value === undefined
              ) {
                isAnswered = false;
                break;
              }
            }
          }
          if (!isAnswered) break;
        }
        if (!isAnswered) break;
      }
      return isAnswered;
    },
    getNewAttachedPhotoList() {
      let newAttachedPhotosList = [];
      for (const [surveyId, surveyItem] of Object.entries(
        this.worksessionSurveyResponse.details
      )) {
        for (const [surveyQuestionId, surveyQuestionItem] of Object.entries(
          surveyItem?.surveyQuestionDetails
        )) {
          if (surveyQuestionItem.questionType === "photo") {
            if (
              surveyQuestionItem.assignType !== "OTHERS" &&
              Object.keys(surveyQuestionItem?.details).length > 0
            ) {
              for (const [proOrMatId, proOrMatItem] of Object.entries(
                surveyQuestionItem?.details
              )) {
                if (proOrMatItem.value) {
                  for (let i = 0; i < proOrMatItem.value.length; i++) {
                    let pushItem = {
                      surveyId,
                      surveyQuestionId,
                      proOrMatId,
                      fileObject: proOrMatItem.value[i],
                    };
                    newAttachedPhotosList.push(pushItem);
                  }
                }
              }
            } else {
              if (surveyQuestionItem.value) {
                for (let i = 0; i < surveyQuestionItem.value.length; i++) {
                  let pushItem = {
                    surveyId,
                    surveyQuestionId,
                    proOrMatId: null,
                    fileObject: surveyQuestionItem.value[i],
                  };
                  newAttachedPhotosList.push(pushItem);
                }
              }
            }
          }
        }
      }
      return newAttachedPhotosList;
    },
    async onSaveClick() {
      if (!this.isAnsweredForAllMandatoryQuestions()) {
        logError("Debe responder a todas las preguntas.");
      } else {
        this.isSaving = true;
        let newAttachedPhotos = this.getNewAttachedPhotoList();
        let uploadedPhotos = [];
        for (const attachedItem of newAttachedPhotos) {
          let { data } = await ApiService.uploadFile(attachedItem.fileObject);
          let uploadedStatusPushItem = {
            surveyId: attachedItem.surveyId,
            surveyQuestionId: attachedItem.surveyQuestionId,
            proOrMatId: attachedItem.proOrMatId,
            photoId: data.id,
          };
          uploadedPhotos.push(uploadedStatusPushItem);
        }
        let body = {
          worksessionSurveyResponse: this.worksessionSurveyResponse,
          uploadedPhotos,
          worksessionPosBrandId: this.worksessionPosBrandId,
          worksession_survey_actions: this.worksession_survey_actions,
        };
        try {
          await ApiService.post(`worksession/saveSessionSurveyData`, body);
          this.isSaving = false;
          logInfo("Éxito");
          await this.init(this.selectedBrandId);
        } catch (error) {
          this.isSaving = false;
        }
      }
    },
    async init(activeBrandId) {
      const { id, worksessionPosId } = this.$route.params;
      this.sessionId = id;
      this.worksessionPosId = worksessionPosId;
      const {
        data,
        reasonTypes,
        worksessionPosBrands,
        promosTypes,
        placeTypes,
      } = await ApiService.get(`worksession/pos/${this.worksessionPosId}`);
      this.posSession = data;
      this.worksessionPosBrands = worksessionPosBrands;

      if (this.posSession && this.posSession?.po) {
        const { chainId, subChainId, sub2ChainId } = this.posSession.po;
        this.posSessionChainCriteria = {
          chainId,
          subChainId,
          sub2ChainId,
        };
      }

      if (activeBrandId) {
        this.selectedBrandId = parseInt(activeBrandId);
      } else if (this.brandId) {
        this.selectedBrandId = parseInt(this.brandId);
      }
      if (this.selectedBrandId) {
        this.selectedBrand = this.worksessionPosBrands.find(
          (el) => el.id === this.selectedBrandId
        );
        await this.getSessionSurveyData();
      }
    },
  },

  async mounted() {
    await this.init(undefined);
  },
};
</script>

<style>
.title_component {
  background: #666;
  padding: 0 10px;
  color: #fff;
  font-weight: 300 !important;
}
.lightbox_image_item {
  max-width: 100%;
  height: 300px;
  cursor: pointer;
  background-position: center;
  background-size: contain;
}
.back_green {
  background: #c5e0b4;
}
.back_red {
  background: #e0b4b4;
}
.v-chip.v-size--default.added_action_chip {
  height: auto;
}
.sectionTitle {
  background: #ddd;
  padding: 10px;
  margin: 0 !important;
}
.sectionContents {
  padding: 10px;
}
</style>
